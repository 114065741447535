import React, { useState, useEffect } from 'react';
import { Button, Layout, Row, Col, Table, Modal, message, Tooltip } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './index.css';
import { BIVE_INFO, networks, IDOColumnsTable, BSCChainID } from '../../constants';
import { initContract } from '../../contract/IDOWhiteListContract';
import { Spin } from 'antd';
import { ethers } from 'ethers';
import * as constants from '../../utils/constants';
import SocialLoginButton from '../SocialLoginButton';
import getUserData from '../../utils/getUserData';
import {
  OWNER_SMART_CONTRACT_ADDRESS,
  SPENDER_CONTRACT_ADDRESS,
  USDT_PRICE_IDO
} from '../../constants';
// import { SignOut } from '../MenuDropdown';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../../utils/dateFormat'

const isMobile = require('is-mobile');

function IDOWhiteList() {
  const navigate = useNavigate();
  const [account, setAccount] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const [contract, setContract] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [dataModal, setDataModal] = useState({});
  const [disabledAllClaimButton, setDisabledAllClaimButton] = useState(false);
  //   const [modalConnectWallet, setModalConnectWallet] = useState(false);
  const [isPrevent, setIsPrevent] = useState(false);
  const [isClaiming, setIsClaiming] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [deposit, setDeposit] = useState({});
  const [confirmDeposit, setConfirmDeposit] = useState(false);
  const [isDepositDiffAccount, setIsDepositDiffAccount] = useState(false);
  const [rejectTrans, setRejectTrans] = useState(false);
  const [notEnoughBalance, setNotEnoughBalance] = useState(false);

  //   state for patch
  const [patchList, setPatchList] = useState([]);

  //   check login with social
  useEffect(() => {
    const userData = localStorage.getItem('userData');
    if (!userData) {
      const url = new URL(window.location.href);
      const code = url.searchParams.get('code');
      if (code) {
        getUserData(code);
      }
    }
  });

  //   fetch account
  const fetchAccount = () => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    if (userData && userData.social_wallet) {
      setAccount(userData.social_wallet);
    }
  };
  useEffect(() => {
    fetchAccount();
  }, [account]);

  //   fet ido whitelist data
  const fetchIDOWhitelistData = async () => {
    try {
      if (!contract) {
        await getContract();
      }
      console.log(account, 'account data');
      console.log(contract, 'contract data');
      await getAllocation();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (contract && account.length > 0) {
      fetchIDOWhitelistData();
    }
  }, [account, contract]);

  useEffect(() => {
    async function fetchData() {
      //   if (!account && window.ethereum) {
      //     try {
      //       const provider = new ethers.providers.Web3Provider(window.ethereum);
      //       let accountFetch = '';
      //       const signer = provider.getSigner();
      //       accountFetch = await signer.getAddress().then(async (result) => {
      //         return result;
      //       });

      //       if (accountFetch) {
      //         await setAccount(accountFetch);
      //         await getContract();
      //       }
      //     } catch (err) {
      //       if (err.code === constants.metamaskErrors.CONNECT_ERROR) {
      //         message.error({
      //           className: 'custom-message',
      //           content: 'Please connect to MetaMask.'
      //         });
      //       } else {
      //         console.error(err);
      //       }
      //     }
      //   } else {
      if (
        window.ethereum &&
        window.ethereum.chainId &&
        ethers.utils.hexlify(BSCChainID).toString() !==
        window.ethereum.chainId?.toString()
      ) {
        console.log('wrong network fetch data...');
        setIsPrevent(true);
      } else {
        getContract();
      }
      //   }
    }
    if (!isMobile() && !account) {
      fetchData();
    }
  }, []);

  //   const showModal = (amount) => {
  //     setDataModal(amount);
  //     setIsModalVisible(true);
  //   };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (window.ethereum && account) {
      window.ethereum.on('chainChanged', (data) => {
        if (
          parseInt(BSCChainID).toString() !==
          parseInt(window.ethereum.chainId).toString()
        ) {
          console.log('wrong network when chain changed');
          setIsPrevent(true);
        } else {
          setIsPrevent(false);
          window.location.reload();
        }
      });
      //   window.ethereum.on('accountsChanged', async function (accounts) {
      //     try {
      //         console.log(accounts.toString(),"accounts,....");
      //         console.log(account.toString(),"kjklklk")
      //       if (accounts[0].toString() !== account.toString()) {
      //         message.warning({
      //             className: 'custom-message',
      //             content: 'Your account is not linked to this wallet, please switch to social wallet'
      //         })
      //       }
      //     } catch (err) {
      //       setAccount('');
      //     }
      //   });
    }
  }, [account]);

  const getContract = async () => {
    const { ido_contract, erc_20_contract } = await initContract();
    console.log(ido_contract, 'ido contract');
    if (ido_contract && erc_20_contract) {
      await setContract({
        ido: ido_contract,
        erc20: erc_20_contract
      });
    }
  };

  const getAllocation = async () => {
    try {
      if (
        parseInt(BSCChainID).toString() !==
        parseInt(window.ethereum.chainId).toString()
      ) {
        console.log('wron network get allocation ');
        setIsPrevent(true);
      } else {
        if (contract) {
          setLoading(true);
          try {
            await contract.ido.callContract
              .walletOfOwner(account)
              .then(async (data) => {
                if (data.length) {
                  let allPatchRoundPurchase = {};
                  const dataPatchList = await data.map((patch) => {
                    let roundName;
                    switch (Number(patch.patchID)) {
                      case 1: {
                        roundName = "Bizverse vrLand Owners"
                        break;
                      }
                      default: {
                        roundName = "GFS Event"
                        break;
                      }
                    }
                    allPatchRoundPurchase[patch.patchID] = false;
                    return {
                      isPurchased: patch.purchased,
                      walletAddress: patch.owner
                        ? patch?.owner?.substring(0, 6) +
                        '...' +
                        patch?.owner?.substring(
                          patch.owner.length - 4,
                          patch.owner.length
                        )
                        : 'No wallets linked',
                      patchId: patch.patchID,
                      amount: ethers.utils.formatUnits(patch.amount, 6),
                      id: patch.id,
                      roundName: roundName,
                    };
                  });
                  let patchListWithExpTime = []
                  for (let index = 0; index < dataPatchList.length; index++) {
                    const expTime = await contract.ido.callContract.getPatchById(dataPatchList[index].patchId)
                    console.log(expTime.expiredAt.toNumber(10), "expire time")
                    const formatExpTime = formatDate(new Date(Number(expTime.expiredAt.toNumber(10)) * 1000).toUTCString()) // 1000 is the number need to multiple when fetch expire time from  contract
                    patchListWithExpTime.push({ ...dataPatchList[index], expTime: formatExpTime })
                  }
                  setPatchList(patchListWithExpTime);
                  setIsClaiming(allPatchRoundPurchase);
                  return dataPatchList
                }
              })
              .catch((err) => {
                console.log(err);
              })
              .finally(() => {
                setLoading(false);
              });
          } catch (err) {
            console.log(err)
            message.error({
              className: 'custom-error',
              content: 'Something went wrong!'
            });
          }
        } else {
          await getContract();
        }
      }
    } catch (error) {
      message.error({
        className: 'custom-message',
        content: error.message
      });
    } finally {
      setLoading(false);
    }
  };

  const addBiveToWallet = async () => {
    if (window.ethereum) {
      try {
        await getWallet();
        await window.ethereum.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20',
            options: BIVE_INFO
          }
        });
      } catch (error) {
        message.error({
          className: 'custom-message',
          content: error.message
        });
      }
    } else {
      setIsVisible(true);
      return false;
    }
  };

  const getWallet = async () => {
    if (window.ethereum) {
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        let account = '';
        const network = await provider.getNetwork();
        if (network.chainId.toString() !== networks[0].chainId.toString()) {
          const params = {
            chainId: ethers.utils.hexValue(networks[0].chainId), // A 0x-prefixed hexadecimal string
            chainName: networks[0].name,
            nativeCurrency: {
              name: networks[0].nativeCurrency.name,
              symbol: networks[0].nativeCurrency.symbol, // 2-6 characters long
              decimals: networks[0].nativeCurrency.decimals
            },
            rpcUrls: networks[0].rpc,
            blockExplorerUrls: [
              networks[0].explorers &&
                networks[0].explorers.length > 0 &&
                networks[0].explorers[0].url
                ? networks[0].explorers[0].url
                : networks[0].infoURL
            ]
          };

          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [params]
          });
        }

        await window.ethereum
          .request({ method: 'eth_requestAccounts' })
          .then(async () => {
            const signer = provider.getSigner();
            account = await signer.getAddress().then((result) => {
              return result;
            });
          });
        if (!account) {
          message.error({
            className: 'custom-message',
            content: 'Error when connect to metamask account'
          });
        }
        return account;
      } catch (err) {
        if (err.code === constants.metamaskErrors.CONNECT_ERROR) {
          message.error({
            className: 'custom-message',
            content: 'Please connect to MetaMask.'
          });
        } else {
          console.error(err);
        }
      } finally {
        // setModalConnectWallet(false);
      }
    } else {
      setIsVisible(true);
      return '';
    }
  };

  const handleReload = async () => {
    await setLoading(true);
    await getAllocation();
    await setConfirmDeposit(false);
    await setDisabledAllClaimButton(false);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const purchase = async (patch) => {
    try {
      await setConfirmDeposit(false);
      setDisabledAllClaimButton(true);
      let isClaimingUpdateLoading = { ...isClaiming };
      isClaimingUpdateLoading[patch.patchId] = true;
      await setIsClaiming(isClaimingUpdateLoading);

      // fetch wallet address
      const currentWalletAddress = await getWallet();
      const socialWallet = JSON.parse(localStorage.getItem('userData'));

      if (
        currentWalletAddress.toString().toLocaleLowerCase() !==
        socialWallet.social_wallet.toString().toLocaleLowerCase()
      ) {
        setDisabledAllClaimButton(false);
        setIsDepositDiffAccount(true);
        return;
      }

      // check allowance with contract
      let tx_allowance = await contract.erc20.callContract
        .allowance(currentWalletAddress, SPENDER_CONTRACT_ADDRESS)
        .catch((err) => {
          console.log(err);
        });

      if (tx_allowance.gt(ethers.BigNumber.from(0))) {
        // check balance
        let tx_balance = await contract.erc20.callContract
          .balanceOf(currentWalletAddress)
          .catch((err) => {
            console.log(err, 'erorr in get balance.....');
          });
        const balance = ethers.utils.formatUnits(tx_balance, 4);
        if (Number(balance) < Number(patch.amount)) {
          setNotEnoughBalance(true);
          setConfirmDeposit(false);
          setDisabledAllClaimButton(false);
          return;
        }
        // purchase process
        let tx = await contract.ido.executeContract.purchase(
          patch.id.toNumber(10),
          { gasLimit: constants.contract.GAS_LIMIT_HEXA }
        );
        await tx
          .wait()
          .then(async (data) => {
            await setConfirmDeposit(false);
            message.success({
              className: 'custom-message',
              content:
                'We have received your IDO payment, you will receive BIVE when we organize TGE.'
            });
            handleReload();
          })
          .catch((err) => {
            console.log('transaction failed', err);
            message.error({
              className: 'custom-message',
              content: 'Transaction failed!'
            });
            handleReload();
          });
      } else {
        let tx_approve = await contract.erc20.executeContract.approve(
          SPENDER_CONTRACT_ADDRESS,
          ethers.constants.MaxUint256,
          {
            gasLimit: constants.contract.GAS_LIMIT_HEXA
          }
        );

        await tx_approve
          .wait()
          .then(async (data) => {
            await setConfirmDeposit(false);
            message.success({
              className: 'custom-message',
              content: 'Approve successfully'
            });
            handleReload();
          })
          .catch((err) => {
            console.log('approve failed', err);
            message.error({
              className: 'custom-message',
              content: 'Approve failed!'
            });
            handleReload();
          })
          .finally(() => {
            setConfirmDeposit(false);
            setDisabledAllClaimButton(false);
          });
      }

      setDisabledAllClaimButton(false);
      let isClaimingUpdate = { ...isClaiming };
      isClaimingUpdate[patch.patchId] = false;
      await setIsClaiming(isClaimingUpdate);
    } catch (err) {
      setConfirmDeposit(false);
      if (err.code === 4001) {
        setRejectTrans(true);
      } else {
        message.error({
          className: 'custom-message',
          content: 'Transaction failed!'
        });
        handleReload();
      }
    }
  };

  let data = [];
  if (patchList.length) {
    patchList.forEach((patch, index) => {
      console.log(patch, "patch looop")
      data.push({
        key: index,
        round: patch.roundName,
        total: Number(patch.amount / USDT_PRICE_IDO).toFixed(4),
        walletAddress: patch.walletAddress,
        state: patch.isPurchased ? 'Paid' : 'Unpaid',
        amount: patch.amount,
        paymentDeadline: patch.expTime,
        action: (
          <button
            className={`button btn-connect-wallet deposit-btn ${patch.isPurchased ? 'disabled-deposit-btn' : ''
              }`}
            onClick={() => {
              setConfirmDeposit(true);
              setDeposit(patch);
            }}
            disabled={patch.isPurchased || disabledAllClaimButton}
          >
            <span>Purchase</span>
            {isClaiming[patch.patchId] && <LoadingOutlined spin />}
          </button>
        )
      });
    });
  }


  //  get avatar url
  const avatarSrc = localStorage.getItem('userData')
    ? JSON.parse(localStorage.getItem('userData')).avatar
    : '';

  return (
    <Layout style={{ height: '100vh' }}>
      <Modal
        visible={isVisible}
        onCancel={() => setIsVisible(false)}
        footer={null}
        className="metamask-modal"
      >
        <span>
          Metamask not detected. Please{' '}
          <a
            href="https://metamask.io/download.html"
            target="_blank"
            rel="noreferrer"
          >
            {' '}
            install extension{' '}
          </a>{' '}
          and try again
        </span>
      </Modal>

      {/* model purchase confirm */}
      <Modal
        title="Confirm Purchase"
        visible={confirmDeposit}
        onCancel={() => setConfirmDeposit(false)}
        className="metamask-modal"
        footer={[
          <>
            <Button
              className="btn-connect-wallet"
              key="cancel"
              type="primary"
              onClick={() => setConfirmDeposit(false)}
            >
              Close
            </Button>
            <Button
              className="btn-connect-wallet"
              key="submit"
              type="primary"
              onClick={() => purchase(deposit)}
            >
              Purchase
            </Button>
          </>
        ]}
      >
        <div className="confirm-deposit-body">
          <p>Wallet Address: {deposit.walletAddress}</p>
          <p>Round: {deposit?.roundName}</p>
          <p>Amount: {deposit.amount} (USDT)</p>
          {/* <p>patch.id</p> */}
        </div>
      </Modal>

      {/* modal for deposit difference account */}
      <Modal
        visible={isDepositDiffAccount}
        onCancel={() => {
          setIsDepositDiffAccount(false);
          handleReload();
        }}
        footer={null}
        className="metamask-modal model-wrong-wallet-account"
      >
        <p className="deposit-diff-account">
          Your wallet address in MetaMask is different from the current selected
          account, please select the right account wallet to continue using!
        </p>
        <p className="deposit-diff-account">Linked your: {account}</p>
      </Modal>

      {/* model rejectTrans */}
      <Modal
        visible={rejectTrans}
        onCancel={() => {
          setRejectTrans(false);
          handleReload();
        }}
        footer={null}
        className="metamask-modal denied-transaction-modal"
      >
        <p className="deposit-diff-account">The transaction has been denied!</p>
      </Modal>

      {/* model noe enough balance */}
      <Modal
        visible={notEnoughBalance}
        onCancel={() => {
          setNotEnoughBalance(false);
          handleReload();
        }}
        footer={null}
        className="metamask-modal not-enough-balance"
      >
        <p className="deposit-diff-account">Not enough balance</p>
      </Modal>

      <Layout className="site-layout">
        <div className="site-layout-background">
          <div className="header-claim-bive">
            <div className="bizverse-logo-wrapper">
              <h1>
                <a href="https://bizverse.world">
                  <img
                    className="bive-logo"
                    src="./bizverse-logo.png"
                    alt="Bizverse logo"
                  />
                </a>
              </h1>
              <p className="logo-title">THE METAVERSE FOR BUSINESS</p>
            </div>
            <div className="btn-connect-wrapper">
              <Button
                className="btn-connect-wallet btn-tutorial"
                onClick={() =>
                  window.open(
                    'https://documentations.bizverse.world/faqs/how-to-claim-purchased-bive-token',
                    '_blank'
                  )
                }
              >
                <i className="fa-solid fa-book"></i>
                <span>Tutorial</span>
              </Button>

              {account && !isMobile() ? (
                <>
                  <Button
                    className="btn-add-bive-to-wallet"
                    onClick={() => addBiveToWallet()}
                  >
                    <span className="btn-connect-wallet btn-add-bive">
                      <img
                        className="bive-logo add-bive-token"
                        src="./bive.png"
                        alt="Bizverse logo"
                      />
                      <span> Add BIVE to my wallet </span>
                    </span>
                  </Button>
                </>
              ) : (
                ''
              )}

              <SocialLoginButton setAccount={setAccount} />
              {/* avatar from social */}
              {avatarSrc ? (
                <img src={avatarSrc} className="avt" alt="avatar-social" />
              ) : (
                ''
              )}
              {/* dropdown menu */}
              {/* {localStorage.getItem('userData') &&
                localStorage.getItem('access_token') ? (
                <SignOut setAccount={setAccount} />
              ) : (
                ''
              )} */}
            </div>
          </div>
        </div>
        <div className="content-container">
          {!isMobile() ? (
            account && contract ? (
              isLoading ? (
                <div className="loading-wrapper">
                  <Spin
                    indicator={
                      <LoadingOutlined style={{ fontSize: 64 }} spin />
                    }
                  />
                </div>
              ) : data.length && !isMobile() ? (
                <>
                  <div className="table-wrapper">
                    <div className="reload-btn-wrapper">
                      <Button
                        className="btn-connect-wallet reload-table"
                        onClick={() => navigate('/')}
                      >
                        Back{' '}
                      </Button>
                      <Button
                        className="btn-connect-wallet reload-table"
                        onClick={() => handleReload()}
                      >
                        Refresh{' '}
                      </Button>
                    </div>

                    <Table
                      columns={IDOColumnsTable}
                      dataSource={data}
                      pagination={false}
                      scroll={{ x: 'inherit' }}
                      rowKey="id"
                    />
                    <div className="pay-note">
                      <p>
                        {' '}
                        If you haven't paid, please complete this payment
                        before payment deadline
                      </p>
                      <p>
                        Note: Cases of failure to pay on time will be removed
                        from this IDO Whitelist.
                      </p>
                      <div />
                    </div>
                  </div>
                </>
              ) : (
                <div className="message-purchase-wrapper">
                  <p className="message-purchase">
                    You don't have any purchases
                  </p>
                </div>
              )
            ) : (
              <div className="message-purchase-wrapper">
                <p className="message-purchase">
                  Please connect wallet to see your purchases
                </p>
              </div>
            )
          ) : (
            <p className="message-purchase">
              The data are not available on mobile, please try again on the
              desktop to claim your token
            </p>
          )}
          <footer className="footer">
            All Right Reserved. © Copyright 2022
          </footer>
        </div>
      </Layout>
      {/* modal detail of each turn */}
      <Modal
        title={null}
        visible={isPrevent}
        style={{ color: 'white' }}
        footer={null}
        closable={false}
        className="modal-message"
      >
        <div className="modal-detail-container modal-message-custom-check-network">
          Wrong network!
          <p>
            Please{' '}
            <span
              onClick={() => {
                window.ethereum.request({
                  method: 'wallet_switchEthereumChain',
                  params: [
                    { chainId: ethers.utils.hexValue(networks[0].chainId) }
                  ]
                });
              }}
              className="highlight-text"
            >
              change
            </span>{' '}
            your network to BSC Mainnet to continue
          </p>
        </div>
      </Modal>
    </Layout>
  );
}

export default IDOWhiteList;
