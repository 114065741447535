const metamaskErrors = {
  CONNECT_ERROR: 4001,
};

const modalData = {
  seed: {
    name: "SEED",
    contractAddress: "0xE1c7F50B3B790369ecaA6D37FC9b9939FedE77A4",
    total: "50,000,000",
    context: [
      "5% released at TGE",
      "Then 12 months cliff",
      "Then linear vesting over 24 months",
    ],
  },
  private: {
    name: "PRIVATE",
    contractAddress: "0x0D6606A286BB61C61A13C781E8A1ae9cD66d72e0",
    total: "150,000,000",
    context: [
      "10% released at TGE",
      "Then 10 cliff 10 month",
      "Then linear vesting over 18 months",
    ],
  },
  public: {
    name: "PUBLIC",
    contractAddress: "0x53fD467e68bDc91Be2448cbB64CD4c1b19D18A96",
    total: "10,000,000",
    context: ["Released 100% at TGE"],
  },
  team: {
    name: "TEAM",
    contractAddress: "0xf1E14f01A8f8155cc23FB2E41cBD2BF0fD62412E",
    total: "150,000,000",
    context: ["12 months cliff", "Then 24 monthly released"],
  },
  advisor: {
    name: "ADVISOR",
    contractAddress: "0x92C59233C0321eEAf8A595aAf551C44aeda1ab01",
    total: "50,000,000",
    context: ["12 months cliff", "Then 12 monthly released"],
  },
  lp: {
    name: "LP",
    contractAddress: "0x939D0DbC7da4Ef178fFd9f25137bFeA2422319AD",
    total: "20,000,000",
    context: ["Released 100% at TGE"],
  },
  staking: {
    name: "STAKING",
    contractAddress: "0x179A7b91D4746A7Ef7ff68aABB241Bc4DDf6A229",
    total: "100,000,000",
    context: ["Cliff 01 months", "Released monthly by 2.5%"],
  },
  partnerships: {
    name: "PARNERSHIPS",
    contractAddress: "0xc43df049990c20C750CaCdf261F368020E4Bd1af",
    total: "70,000,000",
    context: ["Cliff 6 months", "Then linear released over 24 months"],
  },
  community: {
    name: "COMMUNITY",
    contractAddress: "0xc37c301a519c4138Cfa201bd55b034CCd2D483a0",
    total: "30,000,000",
    context: ["Released monthly by 2.5%"],
  },
  ecosystem: {
    name: "ECOSYSTEM",
    contractAddress: "0xaCF1a96D862CF9195BD45865744fb59F625B7800",
    total: "270,000,000",
    context: ["Cliff 12 months", "Linear release over 36 months"],
  },
  marketing: {
    name: "MARKETING",
    contractAddress: "0x589E119FE22F8267c1635a48dB959B970250B89a",
    total: "100,000,000",
    context: ["2% released at TGE", "2% monthly vesting"],
  },
};

const contract = {
  KEY: [
    "seed",
    "private",
    "public",
    "public2",
    "team",
    "teamNew",
    "advisor",
    "liquidity",
    "marketing",
    "staking",
    "community",
    "ecosystem",
    // "partnerships",
  ],
};

export { metamaskErrors, modalData, contract };
