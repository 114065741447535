import React, { useState, useEffect } from "react";
import { Button, Layout, Row, Col, Table, Modal, message, Tooltip } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "./App.css";
import {
  BIVE_INFO,
  BSCChainID,
  BSC_SCAN_DOMAIN,
  columnsTable,
  MESSAGE,
  networks,
} from "./constants";
import { initContract } from "./contract";
import { Spin } from "antd";
import { ethers } from "ethers";
import * as constants from "./utils/constants";

const isMobile = require("is-mobile");

const modalData = constants.modalData;
const listContractKey = constants.contract.KEY;

function App() {
  const [account, setAccount] = useState("");
  const [showPopupInstallMetamask, setShowPopupInstallMetamask] =
    useState(false);
  const [contract, setContract] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [dataModal, setDataModal] = useState({});
  const [modalConnectWallet, setModalConnectWallet] = useState(false);
  const [isPrevent, setIsPrevent] = useState(false);
  const [isClaiming, setIsClaiming] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showButtonConnect, setShowButtonConnect] = useState(true);
  const [loadAllocationError, setLoadAllocationError] = useState(false);

  const claimToken = async (contract) => {
    try {
      setIsClaiming(true);
      await getWallet();
      let tx = await contract.executeContract.release();
      await tx.wait().then(async (data) => {
        message.success({
          className: "custom-message",
          content: MESSAGE.CLAIM_BIVE_SUCCESS,
        });
      });
      await handleReload();
    } catch (error) {
      message.error({
        className: "custom-message",
        content: "Reject transaction",
      });
      setTimeout(() => {
        getAllocation();
      }, 700);
    } finally {
      setIsClaiming(false);
    }
  };

  const [allowcationData, setAllowcationData] = useState({
    seed: {
      label: "Seed",
      key: "Seed",
      total: 0,
      released: 0,
      available: 0,
      remaining: 0,
      action: (contract) => claimToken(contract),
    },
    private: {
      label: "Private",
      key: "Private",
      total: 0,
      released: 0,
      available: 0,
      remaining: 0,
      action: (contract) => claimToken(contract),
    },
    public: {
      label: "Public",
      key: "Public",
      total: 0,
      released: 0,
      available: 0,
      remaining: 0,
      action: (contract) => claimToken(contract),
    },
    public2: {
      label: "Public",
      key: "Public_2",
      total: 0,
      released: 0,
      available: 0,
      remaining: 0,
      action: (contract) => claimToken(contract),
    },
    team: {
      label: "Team",
      key: "Team",
      total: 0,
      released: 0,
      available: 0,
      remaining: 0,
      action: (contract) => claimToken(contract),
    },
    teamNew: {
      label: "Team",
      key: "TeamNew",
      total: 0,
      released: 0,
      available: 0,
      remaining: 0,
      action: (contract) => claimToken(contract),
    },
    advisor: {
      label: "Advisor",
      key: "Advisor",
      total: 0,
      released: 0,
      available: 0,
      remaining: 0,
      action: (contract) => claimToken(contract),
    },
    liquidity: {
      label: "Liquidity",
      key: "Liquidity",
      total: 0,
      released: 0,
      available: 0,
      remaining: 0,
      action: (contract) => claimToken(contract),
    },
    marketing: {
      label: "Marketing",
      key: "Marketing",
      total: 0,
      released: 0,
      available: 0,
      remaining: 0,
      action: (contract) => claimToken(contract),
    },
    staking: {
      label: "Staking",
      key: "Staking",
      total: 0,
      released: 0,
      available: 0,
      remaining: 0,
      action: (contract) => claimToken(contract),
    },
    community: {
      label: "Community",
      key: "Community",
      total: 0,
      released: 0,
      available: 0,
      remaining: 0,
      action: (contract) => claimToken(contract),
    },
    ecosystem: {
      label: "Ecosystem",
      key: "Ecosystem",
      total: 0,
      released: 0,
      available: 0,
      remaining: 0,
      action: (contract) => claimToken(contract),
    },
  });

  useEffect(() => {
    if (contract && account?.length > 0) {
      getAllocation();
    }
  }, [account, contract]);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (window.ethereum && account) {
      // getContract();
      window.ethereum.on("chainChanged", (data) => {
        if (
          parseInt(BSCChainID).toString() !==
          parseInt(window.ethereum.chainId).toString()
        ) {
          setIsPrevent(true);
        } else {
          setIsPrevent(false);
          // window.location.reload();
        }
      });
      window.ethereum.on("accountsChanged", async function (accounts) {
        try {
          if (
            account &&
            accounts[0].toString()?.toLowerCase() !==
            account.toString().toLowerCase()
          ) {
            setAccount(accounts[0]?.toLowerCase());
          }
        } catch (err) {
          setAccount("");
        }
      });
    }
  }, [account]);

  const getContract = async () => {
    const { private_contract,
      public_contract,
      public_2_contract,
      seed_contract,
      team_contract,
      team_new_contract,
      advisor_contract,
      liquidity_contract,
      marketing_contract,
      staking_contract,
      community_contract,
      ecosystem_contract,
    } = await initContract();
    if (private_contract &&
      public_contract &&
      public_2_contract &&
      seed_contract &&
      team_contract &&
      team_new_contract &&
      advisor_contract &&
      liquidity_contract &&
      marketing_contract &&
      staking_contract &&
      community_contract &&
      ecosystem_contract
    ) {
      setContract({
        private: private_contract,
        public: public_contract,
        public2: public_2_contract,
        seed: seed_contract,
        team: team_contract,
        teamNew: team_new_contract,
        advisor: advisor_contract,
        liquidity: liquidity_contract,
        marketing: marketing_contract,
        staking: staking_contract,
        community: community_contract,
        ecosystem: ecosystem_contract,
      });
    }
  };

  const getAllocation = async () => {
    setLoadAllocationError(false);
    setShowButtonConnect(false);
    try {
      if (
        parseInt(BSCChainID).toString() !==
        parseInt(window.ethereum.chainId).toString()
      ) {
        setIsPrevent(true);
      } else {
        if (contract) {
          setIsPrevent(false);
          setLoading(true);
          await Promise.all([
            // seed
            contract.seed.callContract.lockAddress(account),
            contract.seed.callContract.getRelease(account),
            // contract.seed.callContract.remaining(account),
            // // private
            contract.private.callContract.lockAddress(account),
            contract.private.callContract.getRelease(account),
            // contract.private.callContract.remaining(account),
            // public
            contract.public.callContract.lockAddress(account),
            contract.public.callContract.getRelease(account),
            // public2
            contract.public2.callContract.lockAddress(account),
            contract.public2.callContract.getRelease(account),
            // team
            contract.team.callContract.lockAddress(account),
            contract.team.callContract.getRelease(account),
            // teamNew
            contract.teamNew.callContract.lockAddress(account),
            contract.teamNew.callContract.getRelease(account),
            // advisor
            contract.advisor.callContract.lockAddress(account),
            contract.advisor.callContract.getRelease(account),
            // liquidity
            contract.liquidity.callContract.lockAddress(account),
            contract.liquidity.callContract.getRelease(account),
            // marketing
            contract.marketing.callContract.lockAddress(account),
            contract.marketing.callContract.getRelease(account),
            // staking
            contract.staking.callContract.lockAddress(account),
            contract.staking.callContract.getRelease(account),
            // community
            contract.community.callContract.lockAddress(account),
            contract.community.callContract.getRelease(account),
            // ecosystem
            contract.ecosystem.callContract.lockAddress(account),
            contract.ecosystem.callContract.getRelease(account),
          ])
            .then((allowcation) => {
              let dataAmount = {};
              listContractKey.forEach((value, index) => {
                let lockAddressDataIndex = index * 2;
                let getAvailableDataIndex = index * 2 + 1;
                let total = ethers.utils.formatUnits(
                  allowcation[lockAddressDataIndex].total,
                  4
                );
                let released = ethers.utils.formatUnits(
                  allowcation[lockAddressDataIndex].released,
                  4
                );
                let available = ethers.utils.formatUnits(
                  allowcation[getAvailableDataIndex],
                  4
                );
                let remaining = Number(total) - Number(released);
                dataAmount[value] = {
                  label: allowcationData[value]?.label,
                  key: allowcationData[value]?.key,
                  total: Number(total).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }),
                  released: Number(released).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }),
                  remaining: Number(remaining).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }),
                  available: Number(available).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }),
                  action: () => allowcationData[value].action(contract[value]),
                };
              });
              setLoading(false);
              //remove data has total === 0
              Object.keys(dataAmount).forEach((key) => {
                if (dataAmount[key].total <= 0) {
                  delete dataAmount[key];
                }
              });
              setAllowcationData({
                ...dataAmount,
              });
            })
            .catch((err) => {
              setLoadAllocationError(true);
              message.error({
                className: "custom-message",
                content: MESSAGE.GET_ALLOWCATION_ERROR,
              });
            });
        } else {
          await getContract();
        }
      }
    } catch (error) {
      message.error({
        className: "custom-message",
        content: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const addBiveToWallet = async () => {
    if (window.ethereum) {
      try {
        const account = await getWallet();
        if (account) {
          await window.ethereum.request({
            method: "wallet_watchAsset",
            params: {
              type: "ERC20",
              options: BIVE_INFO,
            },
          });
          message.success({
            className: "custom-message",
            content: "Added BIVE to your wallet",
          });
        }
      } catch (error) {
        message.error({
          className: "custom-message",
          content: error.message,
        });
      }
    } else {
      setShowPopupInstallMetamask(true);
    }
  };

  const getWallet = async () => {
    if (window.ethereum) {
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        let account = "";
        const network = await provider.getNetwork();
        if (network.chainId.toString() !== networks[0].chainId.toString()) {
          const params = {
            chainId: ethers.utils.hexValue(networks[0].chainId), // A 0x-prefixed hexadecimal string
            chainName: networks[0].chainName,
            nativeCurrency: {
              name: networks[0].nativeCurrency.name,
              symbol: networks[0].nativeCurrency.symbol, // 2-6 characters long
              decimals: networks[0].nativeCurrency.decimals,
            },
            rpcUrls: networks[0].rpc,
            blockExplorerUrls: networks[0].blockExplorerUrls,
          };

          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [params],
          });
        }
        await window.ethereum
          .request({ method: "eth_requestAccounts" })
          .then(async () => {
            const signer = provider.getSigner();
            account = await signer.getAddress().then((result) => {
              return result;
            });
          });
        if (account) {
          setAccount(account?.toLowerCase());
        } else {
          message.error({
            className: "custom-message",
            content: "Error when connect to metamask account",
          });
        }
        return account;
      } catch (err) {
        if (err.code === constants.metamaskErrors.CONNECT_ERROR) {
          message.error({
            className: "custom-message",
            content: "Can not connect to metamask account",
          });
        } else {
          console.error(err);
        }
      } finally {
        setModalConnectWallet(false);
      }
    } else {
      // show modal install metamask
      setShowPopupInstallMetamask(true);
      return "";
    }
  };

  const handleReload = async () => {
    await setLoading(true);
    await getAllocation();
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  return (
    <Layout style={{ height: "100vh" }}>
      <Modal
        visible={showPopupInstallMetamask}
        onCancel={() => setShowPopupInstallMetamask(false)}
        footer={null}
        className="metamask-modal"
      >
        <span>
          Metamask not detected. Please{" "}
          <a
            href="https://metamask.io/download.html"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            install extension{" "}
          </a>{" "}
          and try again
        </span>
      </Modal>
      <Layout className="site-layout">
        <div className="site-layout-background">
          <div className="header-claim-bive">
            <div className="bizverse-logo-wrapper">
              <h1>
                <a href="https://bizverse.world">
                  <img
                    className="bive-logo"
                    src="./bizverse-logo.png"
                    alt="Bizverse logo"
                  />
                </a>
              </h1>
              <p className="logo-title">THE METAVERSE FOR BUSINESS</p>
            </div>
            <div className="btn-connect-wrapper">
              {account && (
                <div className="account-address">
                  {`${account.slice(0, 6)}...${account.slice(-6)}`}
                  <img
                    src="./MetaMask_Fox.png"
                    alt="metamask logo"
                    width="32px"
                  ></img>
                </div>
              )}
              <div className="btn-connect-wrapper-desktop">
                <Button
                  className="btn-connect-wallet btn-tutorial"
                  onClick={() =>
                    window.open(
                      "https://documentations.bizverse.world/faqs/how-to-claim-purchased-bive-token",
                      "_blank"
                    )
                  }
                >
                  <i className="fa-solid fa-book"></i>
                  <span>Tutorial</span>
                </Button>

                {!isMobile() ? (
                  <>
                    <Button
                      className="btn-add-bive-to-wallet"
                      onClick={() => addBiveToWallet()}
                    >
                      <span className="btn-connect-wallet btn-add-bive">
                        <img
                          className="bive-logo add-bive-token"
                          src="./bive.png"
                          alt="Bizverse logo"
                        />
                        <span> Add BIVE to my wallet </span>
                      </span>
                    </Button>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <Row gutter={3} className="header-info-wrapper">
            <Col span={6} xs={24} lg={6} className="header-info">
              <a
                href={`${BSC_SCAN_DOMAIN}/token/${BIVE_INFO.address}`}
                target="_blank"
                rel="noreferrer"
              >
                <div className="header-card">
                  <img src="./bizverse_bive.png"></img>
                  <div>
                    <h2>1,000,000,000 BIVE</h2>
                    <p>Total Supply</p>
                  </div>
                </div>
              </a>
            </Col>
            <Col span={6} xs={24} lg={6} className="header-info">
              <a
                href={`${BSC_SCAN_DOMAIN}/tokens`}
                target="_blank"
                rel="noreferrer"
              >
                <div className="header-card ">
                  <img src="./bsc-logo.svg"></img>
                  <div>
                    <h2>BSC Mainnet</h2>
                    <p>Blockchain</p>
                  </div>
                </div>
              </a>
            </Col>

            <Col span={6} xs={24} lg={6} className="header-info">
              <a
                href="https://hacken.io/audits/#bizverse"
                target="_blank"
                rel="noreferrer"
              >
                <div className="header-card">
                  <img src="./hacken-logo.svg"></img>
                  <div>
                    <h2>Certik & Hacken</h2>
                    <p>Audited By</p>
                  </div>
                </div>
              </a>
            </Col>
            <Col span={6} xs={24} lg={6} className="header-info">
              <a
                href="https://github.com/BIZVERSE-LAB/audit"
                target="_blank"
                rel="noreferrer"
              >
                <div className="header-card last-header-card">
                  <img src="./ethereum-token.png"></img>
                  <div>
                    <h2>ERC 20</h2>
                    <p>View Smart Contract</p>
                  </div>
                </div>
              </a>
            </Col>
          </Row>
          <div className="contact-container">
            <Tooltip placement="bottom" title="bizverse.world">
              <a
                className="icon-nav-wrapper"
                href="https://bizverse.world/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa-solid fa-globe"></i>
              </a>
            </Tooltip>
            <Tooltip placement="bottom" title="bizverse.io">
              <a
                className="icon-nav-wrapper"
                href="https://bizverse.io"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa-brands fa-buffer"></i>
              </a>
            </Tooltip>
            <Tooltip placement="bottom" title="Telegram">
              <a
                className="icon-nav-wrapper"
                href="https://t.me/bizverseworld/"
                target="_blank"
                rel="noreferrer"
              >
                <i className=" fab fa-telegram"></i>
              </a>
            </Tooltip>
            <Tooltip placement="bottom" title="Twitter">
              <a
                className="icon-nav-wrapper"
                href="https://twitter.com/BizverseWorld/"
                target="_blank"
                rel="noreferrer"
              >
                <i className=" fab fa-twitter"></i>
              </a>
            </Tooltip>
            <Tooltip placement="bottom" title="Facebook">
              <a
                className="icon-nav-wrapper"
                href="https://www.facebook.com/bizverse.world/"
                target="_blank"
                rel="noreferrer"
              >
                <i className=" fab fa-facebook-f"></i>
              </a>
            </Tooltip>
            <Tooltip placement="bottom" title="Discord">
              <a
                className="icon-nav-wrapper"
                href="https://discord.com/invite/xUHHBCJx73"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-discord"></i>
              </a>
            </Tooltip>
          </div>
          {/* <CheckWhiteListStatus contract={contract} showMessage={message} /> */}
        </div>
        <div className="message-purchase-wrapper site-layout-background">
          <p className="message-purchase">
            TOKEN GENERATION EVENT (TGE): <br />
            <span style={{ fontWeight: "900", color: "#1aaeea" }}>
              08:00 UTC, OCTOBER 13TH, 2022 (15h00 GMT+7, OCTOBER 13TH, 2022)
            </span>
          </p>
        </div>
        <div className="content-container">
          {!isMobile() ? (
            account && contract && !showButtonConnect ? (
              isLoading || isClaiming ? (
                <div className="loading-wrapper">
                  <Spin
                    indicator={
                      <LoadingOutlined style={{ fontSize: 64 }} spin />
                    }
                  />
                </div>
              ) : (
                <>
                  <div className="table-wrapper">
                    <div className="reload-btn-wrapper">
                      <Button
                        className="btn-connect-wallet reload-table"
                        onClick={() => handleReload()}
                      >
                        Refresh
                      </Button>
                      {loadAllocationError && (
                        <h3
                          className=""
                          style={{ color: "#e53333", fontStyle: "italic" }}
                        >
                          Load your token allocation failed. Please double-check
                          the connected wallet address and try again.
                        </h3>
                      )}
                    </div>
                    <Table
                      columns={columnsTable}
                      dataSource={Object.values(allowcationData)}
                      pagination={false}
                      scroll={{ x: "inherit" }}
                      locale={{
                        emptyText: "No data found.",
                      }}
                    />
                  </div>
                </>
              )
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h3 className="" style={{ color: "#fff" }}>
                    Please connect wallet to see your token allocation.
                  </h3>
                  <Button
                    className="btn-connect-wallet install-metamask"
                    onClick={() => setModalConnectWallet(true)}
                  >
                    Connect wallet
                  </Button>
                </div>
              </>
            )
          ) : (
            <p className="message-purchase">
              the data are not available on mobile, please try again on the
              desktop to claim your token
            </p>
          )}
          <footer className="footer">
            All Right Reserved. © Copyright 2022
          </footer>
        </div>
      </Layout>

      <Modal
        title={dataModal?.key?.toUpperCase()}
        visible={isModalVisible}
        style={{ color: "white" }}
        onCancel={handleCancel}
        footer={[
          <Button
            className="btn-connect-wallet"
            key="submit"
            type="primary"
            onClick={handleOk}
          >
            Close
          </Button>,
        ]}
      >
        <div className="modal-detail-container">
          <a
            href={`${BSC_SCAN_DOMAIN}/address/${modalData[dataModal.key]?.contractAddress
              }/`}
            target="_blank"
            rel="noreferrer"
            className="btn-connect-wallet btn-view-smart-contract"
          >
            View Smart Contract
          </a>
          <p className="modal-detail-token-allocation">Token Allocation</p>
          <p className="modal-detail-total">
            {modalData[dataModal.key]?.total}
          </p>
          {modalData[dataModal.key]?.context.map((item) => (
            <p className="modal-detail-context">{item}</p>
          ))}
        </div>
      </Modal>
      {/* modal connect metamask  */}
      <Modal
        title="Connect a wallet"
        visible={modalConnectWallet}
        style={{ color: "white" }}
        onCancel={() => setModalConnectWallet(false)}
        footer={null}
        className="metamask-modal"
      >
        <div className="modal-detail-container">
          <p className="term-of-service">
            By connecting a wallet, you agree to Bizverse's{" "}
            <a
              href="https://term.bizverse.world/"
              target="_blank"
              rel="noreferrer"
              className="highlight-text-connect-wallet"
            >
              Team of Use
            </a>{" "}
            and acknowledge that you have read and understand.
          </p>
          {typeof window.ethereum !== "undefined" ? (
            <button
              className="install-metamask btn-metamask btn-connect-wallet"
              onClick={async () => {
                await getWallet().then(async (data) => {
                  if (data && !contract) {
                    await getContract();
                  }
                });
                // .finally(async () => {
                //   await getAllocation();
                // });
              }}
            >
              <span>Connect Metamask</span>
              <img
                src="./MetaMask_Fox.png"
                alt="metamask logo"
                className="metamask-logo "
              ></img>
            </button>
          ) : (
            <a
              href="https://metamask.io/download "
              target="_blank"
              rel="noreferrer"
              className="install-metamask btn-metamask btn-connect-wallet"
            >
              <span>Install Metamask</span>
              <img
                src="./MetaMask_Fox.png"
                alt="metamask logo"
                className="metamask-logo"
              ></img>
            </a>
          )}
        </div>
      </Modal>
      {/* modal detail of each turn */}
      <Modal
        title={null}
        visible={isPrevent}
        style={{ color: "white" }}
        footer={null}
        closable={false}
        className="modal-message"
      >
        <div className="modal-detail-container modal-message-custom-check-network">
          Wrong network!
          <p>
            Please{" "}
            <span
              onClick={() => {
                window.ethereum.request({
                  method: "wallet_switchEthereumChain",
                  params: [
                    { chainId: ethers.utils.hexValue(networks[0].chainId) },
                  ],
                });
              }}
              className="highlight-text"
            >
              change
            </span>{" "}
            your network to BSC Mainnet to continue.
          </p>
        </div>
      </Modal>
    </Layout>
  );
}

export default App;
