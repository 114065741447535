import { Button } from "antd";

// const queryString = window.location.search;
// console.log(queryString);
// const urlParams = new URLSearchParams(queryString);
// const seedAddress = urlParams.get("seedAddress");
// const privateAddress = urlParams.get("privateAddress");
// const publicAddress = urlParams.get("publicAddress");
// console.log({ seedAddress, privateAddress, publicAddress });
// export const REACT_APP_SEED_ADDRESS =
//   seedAddress || "0xd98086d54A50Eb61D260039E5eb30C98F23CE439";
// export const REACT_APP_PRIVATE_ADDRESS =
//   privateAddress || "0x2FDD0988809FD950F0cA8dc86311cafd833d4FB5";
// export const REACT_APP_PUBLIC_ADDRESS =
//   publicAddress || "0x1af9DB4B60E462a4F42C2dfBcf909d37F33700B1";
// export const REACT_APP_CHAIN_ID = "0x61";
// export const REACT_APP_CONTRACT_BIVE =
//   "0xd8ec7641135B1CD647953fE9Eb4Ac9CE22E8Ac5b";
// export const REACT_APP_BSC_SCAN = "https://testnet.bscscan.com";
// export const REACT_APP_ID = "41fa573dfe1539309649";
// export const REACT_APP_SECRET_KEY = "8637d631999dbf2d2c877555f7dee9b318f54e7";
// export const REACT_APP_BIZVERSE_SOCIAL_APP = "https://my-dev.bizverse.world/";
// export const REACT_APP_BIZVERSE_GET_PROFILE =
//   "https://my-dev.bizverse.world/api/profile";
// export const REACT_APP_BIZVERSE_SERVER_KEY = "c16c4d96ae7eae09f9e9100902c478ec";
// export const REACT_APP_BIZVERSE_FUNDING_WALLET =
//   "https://my-dev.bizverse.world/setting/funding_wallet";

// product env
export const REACT_APP_SEED_ADDRESS =
  "0x8EF0DF95ea154B925ac8958161CA87987658b746";
export const REACT_APP_PRIVATE_ADDRESS =
  "0x2D929f3eCf830bB729009Bb5457793DC0678c439";
export const REACT_APP_PUBLIC_ADDRESS =
  "0xfA8606478188058eDcBd890A199B8D8636a0fdc3";
export const REACT_APP_PUBLIC_2_ADDRESS =
  "0xC33729cc4510262aE94Bf43791E01424f9C516Ad";
export const REACT_APP_TEAM_ADDRESS =
  "0x2ec8F48F1fedfea88404e88f98832F1b89Df255E";
export const REACT_APP_TEAM_NEW_ADDRESS =
  "0x07854441226B8F740Ad00f2DD6BF46De1876096b";
export const REACT_APP_ADVISOR_ADDRESS =
  "0xd33a0D11f146e4c748e1e0365D6222b48Fcf021e";
export const REACT_APP_LIQUIDITY_ADDRESS =
  "0xA8F593ad2A8D7e43b3112bC3d9695c27168c9A4c";
export const REACT_APP_MARKETING_ADDRESS =
  "0x7148A2141d79534864B6De894C081Bd259f70626";
export const REACT_APP_STAKING_ADDRESS =
  "0x685dCb249F47A485636b00a1eD28d07d1F8bB2C6";
export const REACT_APP_COMMUNITY_ADDRESS =
  "0x27281AbAdb711BDEc1231054C14fF8114A301b5E";
export const REACT_APP_ECOSYSTEM_ADDRESS =
  "0xFe03833252f23eDF8582073c5A29c3A02A4D1878";

export const REACT_APP_CHAIN_ID = "0x38";
export const REACT_APP_CONTRACT_BIVE =
  "0x130E6203F05805cd8C44093a53C7b50775eb4ca3";
export const REACT_APP_BSC_SCAN = "https://bscscan.com/";
export const REACT_APP_ID = "1614149afa97c2b34cae";
export const REACT_APP_SECRET_KEY = "37b2a8680499e681ed1699bcf0110b1eb394bce";
export const REACT_APP_BIZVERSE_SOCIAL_APP = "https://my.bizverse.world/";
export const REACT_APP_BIZVERSE_GET_PROFILE =
  "https://my.bizverse.world/api/profile";
export const REACT_APP_BIZVERSE_SERVER_KEY =
  "becf4dad39f2c90b2c27502d838f4f5a92b16a69-c9c922785c7a6b4f296bb3e5e82a1a90-16231893";
export const REACT_APP_BIZVERSE_FUNDING_WALLET =
  "https://my.bizverse.world/setting/funding_wallet";

export const BSCChainID = REACT_APP_CHAIN_ID;
export const networks = [
  {
    chainId: BSCChainID,
    // rpc: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
    rpc: ["https://bsc-dataseed1.binance.org"],
    chainName: "Binance Smart Chain Mainnet",
    nativeCurrency: {
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
    },
    blockExplorerUrls: [REACT_APP_BSC_SCAN],
  },
];

export const BIZVERSE_FUNDING_WALLET_LINK = REACT_APP_BIZVERSE_FUNDING_WALLET;

export const columnsTable = [
  {
    title: "Round",
    dataIndex: "label",
    key: "label",
    width: 200,
    align: "center",
    className: "column-table",
    render: (text, record, index) => record.label.toUpperCase(),
  },
  {
    title: "Total Amount (BIVE)",
    dataIndex: "total",
    key: "total",
    width: 230,
    align: "center",
    className: "column-table",
    onCell: () => {
      return { "data-label": "Total Amount (BIVE)" };
    },
  },
  {
    title: "Released Amount (BIVE)",
    dataIndex: "released",
    key: "released",
    className: "column-table",
    align: "center",
    onCell: () => {
      return { "data-label": "Released Amount (BIVE)" };
    },
  },
  {
    title: "Remaining (BIVE)",
    dataIndex: "remaining",
    key: "remaining",
    className: "column-table",
    align: "center",
    onCell: () => {
      return { "data-label": "Remaining (BIVE)" };
    },
  },
  {
    title: "Available (BIVE)",
    dataIndex: "available",
    key: "available",
    className: "column-table",
    align: "center",
    onCell: () => {
      return { "data-label": "Available (BIVE)" };
    },
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
    width: 100,
    className: "column-table",
    align: "center",
    render: (text, record, index) => (
      <Button
        className="button btn-connect-wallet"
        onClick={() => {
          console.log(record);
          record.action();
        }}
        disabled={Math.round(record.available).toString() === "0"}
      >
        <span>CLAIM</span>
      </Button>
    ),
  },
];

export const links = [
  {
    avaiable: true,
    url: "https://telegram.me/bizverseworld",
  },
  {
    avaiable: true,
    url: "https://twitter.com/BizverseWorld",
  },
  {
    avaiable: true,
    url: "https://discord.gg/xUHHBCJx73",
  },
  {
    avaiable: true,
    url: "https://www.facebook.com/bizverse.world",
  },
  {
    avaiable: true,
    url: "https://www.reddit.com/r/Bizverse",
  },
  {
    avaiable: true,
    url: "https://medium.com/@bizverseworld",
  },
];

export const BIVE_INFO = {
  address: REACT_APP_CONTRACT_BIVE,
  symbol: "BIVE",
  decimals: 4,
  image:
    "https://bizverse-avatar.s3.ap-southeast-1.amazonaws.com/BIVE-logo.png", // A string url of the token logo
};
export const bizverseAppId = REACT_APP_ID;
export const bizverseAppSecret = REACT_APP_SECRET_KEY;
export const bizverseLinkSocialApp = REACT_APP_BIZVERSE_SOCIAL_APP;
export const bizverseSeverGetProfile = REACT_APP_BIZVERSE_GET_PROFILE;
export const bizverseServerKey = REACT_APP_BIZVERSE_SERVER_KEY;

export const IDOColumnsTable = [
  {
    title: "Round",
    dataIndex: "round",
    key: "round",
    width: 200,
    align: "center",
    className: "column-table",
    onCell: () => {
      return { "data-label": "Round" };
    },
  },
  {
    title: " Registered Amount (BIVE)",
    dataIndex: "total",
    key: "total",
    width: 230,
    align: "center",
    className: "column-table",
    onCell: () => {
      return { "data-label": " Registered Amount (BIVE)" };
    },
  },
  {
    title: "Amount of participation (USDT)",
    dataIndex: "amount",
    key: "amount",
    className: "column-table",
    align: "center",
    onCell: () => {
      return { "data-label": "Amount of participation (USDT)" };
    },
  },
  {
    title: "Payment Deadline (UTC)",
    dataIndex: "paymentDeadline",
    key: "paymentDeadline",
    className: "column-table",
    align: "center",
    onCell: () => {
      return { "data-label": "Payment deadline" };
    },
  },
  {
    title: "Wallet Address",
    dataIndex: "walletAddress",
    key: "walletAddress",
    className: "column-table",
    align: "center",
    onCell: () => {
      return { "data-label": "Wallet Address" };
    },
  },
  {
    title: "Status",
    dataIndex: "state",
    key: "state",
    className: "column-table",
    align: "center",
    onCell: () => {
      return { "data-label": "Status" };
    },
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
    width: 100,
    className: "column-table",
    align: "center",
    onCell: () => {
      return { "data-label": "CLAIM" };
    },
  },
];

export const USDT_PRICE_IDO = 0.08;

// ==============================product  env==========================
export const OWNER_SMART_CONTRACT_ADDRESS =
  "0x4988a896b1227218e4A686fdE5EabdcAbd91571f"; //usdt smart contract address
export const SPENDER_CONTRACT_ADDRESS =
  "0x8AA37f80FbE1981Ae7B124c4950da8eD5f0CcC96"; //ido-white-list contract address
export const BSC_SCAN_DOMAIN = REACT_APP_BSC_SCAN;
// =============================dev env====================================
// export const OWNER_SMART_CONTRACT_ADDRESS ="0xae746294c763ACCbC5837d9e4D61a16194dd16bE" //usdt smart contract address
// export const SPENDER_CONTRACT_ADDRESS = "0x1531410AE2bBCd5451b67Bebe0E7D9cd0D69D2C8" //ido-white-list contract address
export const MESSAGE = {
  CLAIM_BIVE_SUCCESS: "You have claim token successfully.",
  GET_ALLOWCATION_ERROR: "Load your token allocation failed.",
};
