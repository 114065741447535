import { ethers } from "ethers";
import {
  REACT_APP_ADVISOR_ADDRESS,
  REACT_APP_COMMUNITY_ADDRESS,
  REACT_APP_ECOSYSTEM_ADDRESS,
  REACT_APP_LIQUIDITY_ADDRESS,
  REACT_APP_MARKETING_ADDRESS,
  REACT_APP_PRIVATE_ADDRESS,
  REACT_APP_PUBLIC_2_ADDRESS,
  REACT_APP_PUBLIC_ADDRESS,
  REACT_APP_SEED_ADDRESS,
  REACT_APP_STAKING_ADDRESS,
  REACT_APP_TEAM_ADDRESS,
  REACT_APP_TEAM_NEW_ADDRESS
} from "./constants";
let TGE_ABI = [
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_contractOwner",
        "type": "address"
      },
      {
        "components": [
          {
            "internalType": "address",
            "name": "facetAddress",
            "type": "address"
          },
          {
            "internalType": "enum IDiamondCut.FacetCutAction",
            "name": "action",
            "type": "uint8"
          },
          {
            "internalType": "bytes4[]",
            "name": "functionSelectors",
            "type": "bytes4[]"
          }
        ],
        "internalType": "struct IDiamondCut.FacetCut[]",
        "name": "_diamondCut",
        "type": "tuple[]"
      },
      {
        "components": [
          {
            "internalType": "address",
            "name": "initContract",
            "type": "address"
          },
          {
            "internalType": "bytes",
            "name": "initData",
            "type": "bytes"
          }
        ],
        "internalType": "struct Diamond.Initialization[]",
        "name": "_initializations",
        "type": "tuple[]"
      }
    ],
    "stateMutability": "payable",
    "type": "constructor"
  },
  {
    "stateMutability": "payable",
    "type": "fallback"
  },
  {
    "stateMutability": "payable",
    "type": "receive"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "address[]",
        "name": "beneficiaries",
        "type": "address[]"
      },
      {
        "indexed": false,
        "internalType": "uint256[]",
        "name": "amounts",
        "type": "uint256[]"
      }
    ],
    "name": "BatchLock",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "beneficiary",
        "type": "address"
      }
    ],
    "name": "OwnerWithdraw",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "beneficiary",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "Release",
    "type": "event"
  },
  {
    "inputs": [
      {
        "internalType": "address[]",
        "name": "beneficiaries",
        "type": "address[]"
      },
      {
        "internalType": "uint256[]",
        "name": "amounts",
        "type": "uint256[]"
      }
    ],
    "name": "batchLock",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address[]",
        "name": "beneficiaries",
        "type": "address[]"
      }
    ],
    "name": "calcReleased",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_ownLock",
        "type": "address"
      }
    ],
    "name": "getRelease",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "releasable",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "user",
        "type": "address"
      }
    ],
    "name": "lockAddress",
    "outputs": [
      {
        "components": [
          {
            "internalType": "uint256",
            "name": "total",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "released",
            "type": "uint256"
          }
        ],
        "internalType": "struct Vesting",
        "name": "",
        "type": "tuple"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "beneficiary",
        "type": "address"
      }
    ],
    "name": "ownerWithdraw",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "release",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "user",
        "type": "address"
      }
    ],
    "name": "released",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "user",
        "type": "address"
      }
    ],
    "name": "remaining",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "token",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "user",
        "type": "address"
      }
    ],
    "name": "vested",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "components": [
          {
            "internalType": "address",
            "name": "facetAddress",
            "type": "address"
          },
          {
            "internalType": "enum IDiamondCut.FacetCutAction",
            "name": "action",
            "type": "uint8"
          },
          {
            "internalType": "bytes4[]",
            "name": "functionSelectors",
            "type": "bytes4[]"
          }
        ],
        "indexed": false,
        "internalType": "struct IDiamondCut.FacetCut[]",
        "name": "_diamondCut",
        "type": "tuple[]"
      },
      {
        "indexed": false,
        "internalType": "address",
        "name": "_init",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "bytes",
        "name": "_calldata",
        "type": "bytes"
      }
    ],
    "name": "DiamondCut",
    "type": "event"
  },
  {
    "inputs": [
      {
        "components": [
          {
            "internalType": "address",
            "name": "facetAddress",
            "type": "address"
          },
          {
            "internalType": "enum IDiamondCut.FacetCutAction",
            "name": "action",
            "type": "uint8"
          },
          {
            "internalType": "bytes4[]",
            "name": "functionSelectors",
            "type": "bytes4[]"
          }
        ],
        "internalType": "struct IDiamondCut.FacetCut[]",
        "name": "_diamondCut",
        "type": "tuple[]"
      },
      {
        "internalType": "address",
        "name": "_init",
        "type": "address"
      },
      {
        "internalType": "bytes",
        "name": "_calldata",
        "type": "bytes"
      }
    ],
    "name": "diamondCut",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "previousOwner",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
      }
    ],
    "name": "OwnershipTransferred",
    "type": "event"
  },
  {
    "inputs": [],
    "name": "owner",
    "outputs": [
      {
        "internalType": "address",
        "name": "owner_",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_newOwner",
        "type": "address"
      }
    ],
    "name": "transferOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes4",
        "name": "_functionSelector",
        "type": "bytes4"
      }
    ],
    "name": "facetAddress",
    "outputs": [
      {
        "internalType": "address",
        "name": "facetAddress_",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "facetAddresses",
    "outputs": [
      {
        "internalType": "address[]",
        "name": "facetAddresses_",
        "type": "address[]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_facet",
        "type": "address"
      }
    ],
    "name": "facetFunctionSelectors",
    "outputs": [
      {
        "internalType": "bytes4[]",
        "name": "facetFunctionSelectors_",
        "type": "bytes4[]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "facets",
    "outputs": [
      {
        "components": [
          {
            "internalType": "address",
            "name": "facetAddress",
            "type": "address"
          },
          {
            "internalType": "bytes4[]",
            "name": "functionSelectors",
            "type": "bytes4[]"
          }
        ],
        "internalType": "struct IDiamondLoupe.Facet[]",
        "name": "facets_",
        "type": "tuple[]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes4",
        "name": "_interfaceId",
        "type": "bytes4"
      }
    ],
    "name": "supportsInterface",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  }
];

export const contract = {
  msg: {
    INSUFFICIENT_BALANCE: "INSUFFICIENT_BALANCE",
    INVALID_INPUT: "INVALID_INPUT",
    TRY_LATER: "SYSTEM IS BUSY, PLEASE TRY AGAIN LATER",
  },

  SEED_Address: REACT_APP_SEED_ADDRESS,
  SEED_ABI: TGE_ABI,
  PRIVATE_Address: REACT_APP_PRIVATE_ADDRESS,
  PRIVATE_ABI: TGE_ABI,
  PUBLIC_Address: REACT_APP_PUBLIC_ADDRESS,
  PUBLIC_ABI: TGE_ABI,
  PUBLIC_2_Address: REACT_APP_PUBLIC_2_ADDRESS,
  PUBLIC_2_ABI: TGE_ABI,
  TEAM_Address: REACT_APP_TEAM_ADDRESS,
  TEAM_ABI: TGE_ABI,
  TEAM_NEW_Address: REACT_APP_TEAM_NEW_ADDRESS,
  TEAM_NEW_ABI: TGE_ABI,
  ADVISOR_Address: REACT_APP_ADVISOR_ADDRESS,
  ADVISOR_ABI: TGE_ABI,
  LIQUIDITY_Address: REACT_APP_LIQUIDITY_ADDRESS,
  LIQUIDITY_ABI: TGE_ABI,
  MARKETING_Address: REACT_APP_MARKETING_ADDRESS,
  MARKETING_ABI: TGE_ABI,
  STAKING_Address: REACT_APP_STAKING_ADDRESS,
  STAKING_ABI: TGE_ABI,
  COMMUNITY_Address: REACT_APP_COMMUNITY_ADDRESS,
  COMMUNITY_ABI: TGE_ABI,
  ECOSYSTEM_Address: REACT_APP_ECOSYSTEM_ADDRESS,
  ECOSYSTEM_ABI: TGE_ABI,
};

export const checkMetamaskConnection = async () => {
  if (window.ethereum) {
    try {
      await window.ethereum.request({ method: "eth_requestAccounts" });
      return true;
    } catch (error) {
      return false;
    }
  } else {
    return false;
  }
};

export const createContract = async (abi, contractAddress) => {
  await checkMetamaskConnection();
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();

  const executeContract = new ethers.Contract(contractAddress, abi, signer);
  const callContract = new ethers.Contract(contractAddress, abi, provider);
  return {
    executeContract,
    callContract,
  };
};

export const initContract = async () => {
  const seed_contract = await createContract(
    contract.SEED_ABI,
    contract.SEED_Address
  );
  const private_contract = await createContract(
    contract.PRIVATE_ABI,
    contract.PRIVATE_Address
  );
  const public_contract = await createContract(
    contract.PUBLIC_ABI,
    contract.PUBLIC_Address
  );
  const public_2_contract = await createContract(
    contract.PUBLIC_2_ABI,
    contract.PUBLIC_2_Address
  );
  const team_contract = await createContract(
    contract.TEAM_ABI,
    contract.TEAM_Address
  );
  const team_new_contract = await createContract(
    contract.TEAM_NEW_ABI,
    contract.TEAM_NEW_Address
  );
  const advisor_contract = await createContract(
    contract.ADVISOR_ABI,
    contract.ADVISOR_Address
  );
  const liquidity_contract = await createContract(
    contract.LIQUIDITY_ABI,
    contract.LIQUIDITY_Address
  );
  const marketing_contract = await createContract(
    contract.MARKETING_ABI,
    contract.MARKETING_Address
  );
  const staking_contract = await createContract(
    contract.STAKING_ABI,
    contract.STAKING_Address
  );
  const community_contract = await createContract(
    contract.COMMUNITY_ABI,
    contract.COMMUNITY_Address
  );
  const ecosystem_contract = await createContract(
    contract.ECOSYSTEM_ABI,
    contract.ECOSYSTEM_Address
  );

  return {
    seed_contract,
    private_contract,
    public_contract,
    public_2_contract,
    team_contract,
    team_new_contract,
    advisor_contract,
    liquidity_contract,
    marketing_contract,
    staking_contract,
    community_contract,
    ecosystem_contract,
  };
};
